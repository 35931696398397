@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --tertiary: 222.2 47.4% 11.2%;
    --tertiary-foreground: 270 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --muted2: 210 40% 96.1%;
    --muted2-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;

    --chart-2: 173 58% 39%;

    --chart-3: 197 37% 24%;

    --chart-4: 43 74% 66%;

    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --tertiary: 270 32.6% 17.5%;
    --tertiary-foreground: 270 40% 98%;

    /* Material Design 3 Dark Theme Surface Colors */
    --md-surface-1: 210 40% 4.9%;    /* Surface 1 */
    --md-surface-2: 210 40% 7.9%;    /* Surface 2 */
    --md-surface-3: 210 40% 9.9%;    /* Surface 3 */
    --md-surface-4: 210 40% 11.9%;   /* Surface 4 */
    --md-surface-5: 210 40% 13.9%;   /* Surface 5 */
    
    /* Material Design 3 Dark Theme State Colors */
    --md-state-hover: 210 40% 14.9%;
    --md-state-focus: 210 40% 16.9%;
    --md-state-pressed: 210 40% 11.9%;
    --md-state-dragged: 210 40% 18.9%;

    /* Material Design 3 mapped to shadcn structure */
    --background: 210 40% 4.9%;    /* MD3 Surface 1 */
    --card: 210 40% 7.9%;         /* MD3 Surface 2 */
    --popover: 210 40% 9.9%;      /* MD3 Surface 3 */
    --primary: 210 40% 98%;       /* MD3 Primary */
    --primary-foreground: 210 40% 4.9%;
    
    --secondary: 210 40% 11.9%;   /* MD3 Surface 4 */
    --secondary-foreground: 210 40% 98%;
    
    --muted: 210 40% 13.9%;      /* MD3 Surface 5 */
    --muted-foreground: 210 40% 85%;
    
    --accent: 210 40% 14.9%;     /* MD3 Surface variant */
    --accent-foreground: 210 40% 98%;

    /* New rich dark theme colors */
    --rich-black: 240 10% 3.9%;
    --rich-black-hover: 240 10% 5.9%;
    --rich-black-active: 240 10% 7.9%;
    --rich-border: 240 5% 26%;
  }

  @keyframes glitch {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-2px, 2px);
    }
    40% {
      transform: translate(-2px, -2px);
    }
    60% {
      transform: translate(2px, 2px);
    }
    80% {
      transform: translate(2px, -2px);
    }
    100% {
      transform: translate(0);
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .text-glitch {
    position: relative;
    animation: glitch 0.2s infinite;
    text-shadow: 2px 2px #ff0000, -2px -2px #00ff00;
  }

  .text-gradient {
    @apply bg-clip-text text-transparent;
    background-image: linear-gradient(45deg, #ff0000, #00ff00, #0000ff);
  }

  .border-glitch {
    position: relative;
    border: 2px solid;
    border-image: linear-gradient(45deg, #ff0000, #00ff00, #0000ff) 1;
    animation: glitch 0.2s infinite;
  }

  .button-pixel {
    @apply border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)];
    transition: all 0.1s ease;
  }

  .button-pixel:hover {
    @apply translate-x-[4px] translate-y-[4px] shadow-none;
  }

  .button-neon {
    @apply relative overflow-hidden;
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.5), 0 0 20px rgba(0, 255, 255, 0.3),
      0 0 30px rgba(0, 255, 255, 0.1);
  }
  .button-neon::before {
    content: "";
    @apply absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-20;
    transform: translateX(-100%);
    transition: transform 0.5s;
  }

  .button-neon:hover::before {
    transform: translateX(100%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

/* Radix Portal styles */
#radix-portal {
  @apply fixed;
}

[data-radix-portal] {
  @apply fixed;
}


/* Add this to ensure styles reach portaled content */

